import React from "react";

import dom7_1 from "./img/dom7/dom7_1.jpg";
import dom7_2 from "./img/dom7/dom7_2.jpg";
import dom7_3 from "./img/dom7/dom7_3.jpg";
import dom7_4 from "./img/dom7/dom7_4.jpg";
import waves_1 from "./img/waves/waves_1.jpg";
import waves_2 from "./img/waves/waves_2.jpg";
import waves_3 from "./img/waves/waves_3.jpg";
import waves_4 from "./img/waves/waves_4.jpg";
import waves_5 from "./img/waves/waves_5.jpg";
import cleverchords_1 from "./img/cleverchords/cleverchords_1.jpg";
import cleverchords_2 from "./img/cleverchords/cleverchords_2.jpg";
import cleverchords_3 from "./img/cleverchords/cleverchords_3.jpg";
import law_in_hand_1 from "./img/law_in_hand/law_in_hand_1.jpg";
import law_in_hand_2 from "./img/law_in_hand/law_in_hand_2.jpg";
import law_in_hand_3 from "./img/law_in_hand/law_in_hand_3.jpg";
import law_in_hand_4 from "./img/law_in_hand/law_in_hand_4.jpg";
import law_in_hand_5 from "./img/law_in_hand/law_in_hand_5.jpg";
import no_minute_1 from "./img/no_minute/no_minute_1.jpg";
import no_minute_2 from "./img/no_minute/no_minute_2.jpg";
import no_minute_3 from "./img/no_minute/no_minute_3.jpg";
import no_minute_4 from "./img/no_minute/no_minute_4.jpg";
import yafa_1 from "./img/yafa/yafa_1.jpg";
import yafa_2 from "./img/yafa/yafa_2.jpg";
// import yafa_3 from "./img/yafa/yafa_3.jpg";
import yafa_4 from "./img/yafa/yafa_4.jpg";
import yafa_5 from "./img/yafa/yafa_5.jpg";
// import yafa_6 from "./img/yafa/yafa_6.jpg";
import island_press_1 from "./img/island_press/island_press_1.jpg";
import ecana_1 from "./img/ecana/ecana_1.jpg";
import gk_com_1 from "./img/gk_com/gk_com_1.jpg";
import gk_com_2 from "./img/gk_com/gk_com_2.jpg";

export type Project = {
  images: Array<string>;
  title: string;
  subtitle: string;
  description: React.ReactNode;
  tech: Array<string>;
  links: { text: string; url: string }[];
};

export const projectList = [
  "dominant7",
  "waves",
  "island_press",
  "law_in_hand",
  "no_minute",
  "clever_chords",
  "ecana",
  "yafa",
  "gk_dot_com",
] as const;

// convert projectOrder into string literal union type
type ProjectName = typeof projectList[number];
type ProjectData = Record<ProjectName, Project>;

export const projectData: ProjectData = {
  dominant7: {
    images: [dom7_1, dom7_2, dom7_3, dom7_4],
    title: "Dominant 7",
    subtitle: "Digital Chord Charts",
    description: (
      <>
        <p>
          Chord transcription, song reference, group sharing and editing, and
          memory aid for musicians. Painstakingly crafted for ease of use on
          mobile devices, by professional and amateur musicians alike.
        </p>
        <p>
          Intuitive chord keyboard allows you to add virtually any chord in a
          few taps. A simple numeric beat notation lets you know how long to
          play each chord. Song sheets are clean and compact for easy sight
          reading, with a large, distraction-free display. Transpose songs to
          play in any key. Use tags to organize your songs or create a set list.
        </p>
      </>
    ),
    tech: [
      // back end
      "Node",
      "Express",
      "GraphQL",
      "MongoDB",
      // "Account Auth",
      "JSON Web Token",
      // by "Automation", I mean: sending emails; code that migrated database to 2.0; code that alters and formats the images you use (icons, etc.);
      // "Automation",
      "Heroku",

      // front end
      "React",
      "TypeScript",
      "Relay",
      // "Routing",
      "Styled Components",
      "Custom Animation",
      // "React Spring",
      "SVG",
      "Touch Gestures",
      // "Haptic Feedback",
      "Capacitor",
      "iOS",
      "Android",
      "Dark Mode",
      "In App Purchases",
    ],
    links: [
      {
        text: "iTunes",
        url: "https://apps.apple.com/us/app/dominant-7/id1595702855?platform=iphone",
      },
      {
        text: "Google Play",
        url: "https://play.google.com/store/apps/details?id=com.grantkidner.chordkeeper",
      },
    ],
  },
  waves: {
    images: [waves_1, waves_2, waves_3, waves_4, waves_5],
    title: "Waves Laundry",
    subtitle: "Business Solution for Mobile and Web",
    description: (
      <>
        <p>
          Server, mobile app, and website for laundry and dry cleaning
          pickup/delivery business.
        </p>
        <p>
          Enables customers to manage orders and preferences. Enables employees
          to review orders, create daily itineraries, modify prices and create
          promo codes, and track geographic interest for future business
          expansion.
        </p>
        <p>(Note: This service was suspended due to COVID-19.)</p>
      </>
    ),
    tech: [
      "Node",
      "Express",
      "React",
      "Redux",
      "Stripe API",
      "Google Maps API",
      "Tookan API",
      "CleanCloud API",
      "MongoDB",
      "Automated Email",
      "Ionic",
      "Capacitor",
      "iOS",
      "Android",
    ],
    links: [
      {
        text: "iTunes",
        url: "https://apps.apple.com/us/app/waves-laundry/id1487738139",
      },
      {
        text: "Google Play",
        url: "https://play.google.com/store/apps/details?id=com.waveslaundry.waves",
      },
    ],
  },
  island_press: {
    images: [island_press_1],
    title: "Island Press",
    subtitle: "Custom Solution for Drupal-based Site",
    description: (
      <p>
        Custom web solution for a nonprofit organization, Island Press, in
        collaboration with{" "}
        <a
          href="https://www.daymuse.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Daymuse Studios
        </a>
        .
      </p>
    ),
    tech: ["JS", "PHP", "CSS", "Drupal", "Stripe API", "GoToWebinar API"],
    links: [
      {
        text: "islandpress.org",
        url: "https://islandpress.org",
      },
    ],
  },
  no_minute: {
    images: [no_minute_2, no_minute_3, no_minute_1, no_minute_4],
    title: "No Minute Left Behind",
    subtitle: "Personal Time-Tracker",
    description: (
      <p>Personal time-tracker that provides goal- and budget-tracking.</p>
    ),
    tech: [
      "React Native",
      "Redux",
      "React Spring",
      "Styled Components",
      "D3",
      "SVG",
      "iOS",
      "Android",
    ],
    links: [
      {
        text: "iTunes",
        url: "https://apps.apple.com/us/app/no-minute-left-behind/id1457454515",
      },
      {
        text: "Google Play",
        url: "https://play.google.com/store/apps/details?id=com.grantkidner.nominuteleftbehind",
      },
    ],
  },
  ecana: {
    images: [ecana_1],
    title: "ECANA",
    subtitle: "Custom Solution for Drupal-based Site",
    description: (
      <p>
        Custom web solution for a nonprofit organization, Endometrial Cancer
        Action Network for African-Americans, in collaboration with{" "}
        <a
          href="https://www.daymuse.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Daymuse Studios
        </a>
        .
      </p>
    ),
    tech: ["JS", "CSS", "Drupal"],
    links: [
      {
        text: "ecanawomen.org",
        url: "https://ecanawomen.org/",
      },
    ],
  },
  law_in_hand: {
    images: [
      law_in_hand_1,
      law_in_hand_2,
      law_in_hand_3,
      law_in_hand_4,
      law_in_hand_5,
    ],
    title: "Law in Hand",
    subtitle: "Professional access to Virginia Code of laws",
    description: (
      <p>
        Complete Virginia Code, designed for litigators. Courtroom-friendly with
        fast offline searching, filtering by title, saves, notes, and sharing.
        Includes VCSC sentencing data for criminal litigators.
      </p>
    ),
    tech: [
      "React Native",
      "Redux",
      "Search Indexing",
      "Public APIs",
      "Automated Data Collection",
      "iOS",
      "Android",
    ],
    links: [
      {
        text: "iTunes",
        url: "https://apps.apple.com/us/app/law-in-hand/id1286575866?platform=iphone",
      },
      {
        text: "Google Play",
        url: "https://play.google.com/store/apps/details?id=com.grantkidner.lawinhand",
      },
    ],
  },
  clever_chords: {
    images: [cleverchords_1, cleverchords_2, cleverchords_3],
    title: "Clever Chords",
    subtitle: "Smart Random Progressions",
    description: (
      <p>
        Chord progression generator using principles of music theory. Users can
        play along in-time, while the app plays the chords it shows - or plays a
        metronome pulse - at a user-set tempo. Written long ago using only
        vanilla HTML, JS, and CSS.
      </p>
    ),
    tech: [
      "JS",
      "CSS",
      "HTML",
      "Web Audio API",
      "HTML Canvas",
      "Cordova",
      "Capacitor",
      "iOS",
      "Android",
    ],
    links: [
      {
        text: "iTunes",
        url: "https://apps.apple.com/us/app/clever-chords/id1088589451",
      },
      {
        text: "Google Play",
        url: "https://play.google.com/store/apps/details?id=com.appsforgranted.cleverChords",
      },
    ],
  },
  yafa: {
    images: [yafa_1, yafa_2, yafa_4, yafa_5],
    title: "YAFA",
    subtitle: "Yet Another Flashcard App",
    description: (
      <p>
        Flash cards that use the Leitner memory system to optimally space
        review. Allows online and offline usage, and flash card import.
      </p>
    ),
    tech: [
      "Node",
      "Express",
      "MongoDB",
      "Nodemailer",
      "React",
      "Redux",
      "XState",
      "Ionic",
      "Capacitor",
      "iOS",
    ],
    links: [
      /*  {
        text: "iTunes",
        url: "https://apps.apple.com/us/app/yafa/id1517885490",
      }, */
    ],
  },
  gk_dot_com: {
    images: [gk_com_1, gk_com_2],
    title: "This Site",
    subtitle: "Why not?",
    description: (
      <>
        <p>Personal portfolio of public projects.</p>
        <p>
          I spent a shamelessly long time playing with that animated hero, which
          - in the unlikely event you care - is a live percolation simulation
          using a union-find algorithm. To be clear, I have zero need to know
          anything about percolation.
        </p>
        <p>
          (In retrospect, I should have found a more productive use for your
          CPU's spare clock cycles...)
        </p>
      </>
    ),
    tech: [
      // back end
      "Node",
      "Express",
      "MongoDB",
      "Puppeteer Automated Testing",

      // front end
      "React",
      "TypeScript",
      "JS",
      "CSS",
      "SVG",
      "HTML Canvas",
      "Styled Components",
    ],
    links: [],
  },

  //   {
  //     title: "Freestyle Metronome",
  //     images: [imgFreestyle],
  //     description:
  //       "Looper-style beat generator. Tap a pattern and Freestyle Metronome corrects it to metronome-perfect rhythm, then plays back at any speed.",
  //     iTunesLink:
  //       "https://itunes.apple.com/us/app/freestyle-metronome-custom/id1101538174?mt=8",
  //     PlayLink: "",
  //   },
  //   {
  //     title: "Little Drummer Bot",
  //     images: [imgDrummerBot],
  //     description:
  //       "A little drummer in your pocket! Random, fun drum patterns to use as a metronome or rhythm accompaniment. Sounds include kick, snare, and hi-hat.",
  //     iTunesLink:
  //       "https://itunes.apple.com/us/app/little-drummer-bot-practice-drums-guitar-piano-better/id1137400068?mt=8",
  //     PlayLink:
  //       "https://play.google.com/store/apps/details?id=com.appsforgranted.rhythmGen",
  //   },
  //   {
  //     title: "Fast Email to Self",
  //     images: [imgFastEmail],
  //     description: "",
  //     iTunesLink:
  //       "https://itunes.apple.com/sa/app/fast-email-to-self/id1191123931?mt=8",
  //     PlayLink: "",
  //   },
};
