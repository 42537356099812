import React from "react";
import styled from "styled-components/macro";

type Props = {
  size?: number;
  axis?: "horiz" | "vert";
};

export const Spacer = ({ size = 8, axis }: Props) => {
  const style = {
    "--height": axis === "horiz" ? "1px" : size / 16 + "rem",
    "--width": axis === "vert" ? "1px" : size / 16 + "rem",
  };

  return <Wrapper style={style} />;
};

const Wrapper = styled.span<{
  style: object;
}>`
  display: block;
  width: var(--width);
  min-width: var(--width);
  height: var(--height);
  min-height: var(--height);
  user-select: none;
`;