// you didn't explicitly follow this spec, but it's roughly what you aimed for:
// https://coursera.cs.princeton.edu/algs4/assignments/percolation/specification.php
// https://algs4.cs.princeton.edu/code/javadoc/edu/princeton/cs/algs4/WeightedQuickUnionUF.html

// ideally, split into: UnionFind, Percolate, and Canvas

//https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Optimizing_canvas

import { COLORS } from '../_constants';

export class Percolate {
    index = [];
    nodeCount = [];
    isOpen = [];
    // canvasWidth = 0;
    // canvasHeight = 0;
    // canvasRatio = this.canvasWidth / this.canvasHeight;
    // columns = 200; //20
    // rows = 100;
    isLeaky = true;
    openCount = 0;
    randomInterval = false;
    colorClosed = COLORS.primary;
    // colorOpen = "#444"
    colorOpen = "#222"
    colorPath = COLORS.primaryContrast;
    // colorPath = "#0022ee";
    hasPercolated = false;
    mouse = {
        down: false,
        button: 1,
        x: 0,
        y: 0
    };

    constructor({
        relativeContainer,
        canvasParent,
        canvas,
        columns = 200,
        rows = 100,
        isLeaky = true,
        openPerFrame = 1,
        stopOnPercolation = true,
        callbackOnPercolate = null,
    }) {
        this.relativeContainer = relativeContainer;
        this.$canvas = canvas;
        this.canvasParent = canvasParent;
        this.ctx = this.$canvas.getContext('2d');
        this.columns = columns;
        this.rows = rows;
        this.canvasRatio = this.columns / this.rows
        this.isLeaky = isLeaky;
        this.openPerFrame = openPerFrame;
        this.stopOnPercolation = stopOnPercolation;
        this.callbackOnPercolate = callbackOnPercolate;
        this.createGrid();
        if (isLeaky) {
            // faster, but "leaky"
            this.connectTopBottomVirtual();
        } else {
            // slower, but no "leak" from bottom
            this.connectTopBottomVirtual("top");
        }
        this.resize();
        window.addEventListener('resize', this.resize, false);
        window.addEventListener('orientationchange', this.resize, false);
        var that = this;
        // event listeners
        this.$canvas.onmousedown = function (e) {
            //mouse.button = e.which;
            that.mouse.down = true;
            that.onMouse(e);
        };
        this.$canvas.onmousemove = function (e) {
            if (that.mouse.down === true) {
                that.onMouse(e);
            }
        };
        document.onmouseup = function () {
            return that.mouse.down = false;
        };
        this.$canvas.oncontextmenu = function (e) {
            return e.preventDefault();
        };
    }

    getRoot = (id) => {
        var index = this.index;
        while (index[id] !== id) {
            index[id] = index[index[id]];
            id = index[id];
        }
        return id;
    }

    union = (p, q) => {
        var getRoot = this.getRoot,
            nodeCount = this.nodeCount,
            index = this.index;
        var rootP = getRoot(p);
        var rootQ = getRoot(q);
        if (rootP === rootQ) {
            //console.log("Already connected.");
            return;
        }
        if (nodeCount[rootP] < nodeCount[rootQ]) {
            index[rootP] = rootQ;
        } else {
            index[rootQ] = rootP;
        }
        //console.log("Connected " + p + "(" + rootP + ") and " + q + "(" + rootQ + ")");
    }

    isConnected = (p, q) => {
        var getRoot = this.getRoot;
        return getRoot(p) === getRoot(q);
    }

    createGrid = () => {
        var columns = this.columns,
            rows = this.rows,
            index = this.index,
            nodeCount = this.nodeCount,
            isOpen = this.isOpen;
        var count = columns * rows;
        for (var i = 0; i < count; i++) {
            index[i] = i;
        }
        nodeCount.length = count;
        isOpen.length = count;
        // console.log("Count", count)
    }

    // https://www.html5rocks.com/en/tutorials/casestudies/gopherwoord-studios-resizing-html5-games/
    resize = () => {
        var canvasParent = this.canvasParent;
        var widthToHeight = this.canvasRatio;
        var newWidth = this.relativeContainer.clientWidth;
        var newHeight = this.relativeContainer.clientHeight;
        // console.log(window.innerWidth, this.relativeContainer.clientWidth)
        // var newWidth = window.innerWidth;
        // var newHeight = window.innerHeight;
        var newWidthToHeight = newWidth / newHeight;
        if (newWidthToHeight > widthToHeight) {
            newWidth = newHeight * widthToHeight;
            canvasParent.style.height = newHeight + 'px';
            canvasParent.style.width = newWidth + 'px';
        } else {
            newHeight = newWidth / widthToHeight;
            canvasParent.style.width = newWidth + 'px';
            canvasParent.style.height = newHeight + 'px';
        }
        canvasParent.style.marginTop = (-newHeight / 2) + 'px';
        canvasParent.style.marginLeft = (-newWidth / 2) + 'px';
        // canvasParent.style.fontSize = (newWidth / 400) + 'em';
        this.$canvas.width = newWidth;
        this.$canvas.height = newHeight;
        this.canvasWidth = newWidth;
        this.canvasHeight = newHeight;
        // at present, don't draw "closed" squares
        // this.drawGridInit();
        this.drawGrid();
    }

    findPercThresh = () => {
        var testPermeability = this.testPermeability,
            testPermeabilityFast = this.testPermeability;
        console.time("percThresh");
        var perm = 0.5;
        var percCount = 0;
        var threshold = 0.5;
        var prob = 0;
        var sample = 1000;
        // increment perm decreasingly until reach threshold
        while (prob < threshold) {
            console.log(perm + " " + prob);
            //perm += Math.max(0.05*(1-prob),0.001);
            perm += 0.05 * (threshold - prob);
            percCount = 0;
            for (var i = 0; i < sample; i++) {
                if (testPermeabilityFast(perm) === true) {
                    percCount++;
                }
            }
            prob = percCount / sample;
        }
        testPermeability(perm);
        console.log(perm + " " + prob);
        console.timeEnd("percThresh")
        alert("Threshold: " + perm);
        return perm;
    }

    testPermeabilityFast = (perm) => {
        var index = this.index,
            nodeCount = this.nodeCount,
            isOpen = this.isOpen,
            columns = this.columns,
            rows = this.rows,
            createGrid = this.createGrid,
            connectTopBottomVirtual = this.connectTopBottomVirtual,
            openSpace = this.openSpace,
            isConnected = this.isConnected;
        index.length = 0;
        nodeCount.length = 0;
        isOpen.length = 0;
        createGrid();
        connectTopBottomVirtual();
        for (var i = 0; i < isOpen.length; i++) {
            if (Math.random() < perm)
                openSpace(i)
            else continue;
        }
        // percolation test: testing connection b/w virtual top node and virtual bottom node
        return isConnected(columns * rows, columns * rows + 1);
    }

    testPermeability = (perm) => {
        var index = this.index,
            nodeCount = this.nodeCount,
            isOpen = this.isOpen,
            createGrid = this.createGrid,
            connectTopBottomVirtual = this.connectTopBottomVirtual,
            openSpace = this.openSpace,
            drawGrid = this.drawGrid;
        index.length = 0;
        nodeCount.length = 0;
        isOpen.length = 0;
        createGrid();
        connectTopBottomVirtual("top");
        for (var i = 0; i < isOpen.length; i++) {
            if (Math.random() < perm)
                openSpace(i)
            else continue;
        }
        drawGrid();
        this.testPercolation();
    }

    // *** becomes very slow with larger sets, due to the canvas draws; you already made it significantly faster by not redrawing the closed squares; additional ideas: 1) don't redraw the open squares, instead send to draw fnc the coord of the square just opened, and only change that; that should make sig. faster, though still need to check all squares for percolation; 2) don't update grid on ever iteration; instead, e.g. update it every N/100 times, e.g. for 1000 columns, after every 10 space openings (could do both 1 and 2, but remember would have to send the entire set of newly opened squares to the draw fnc)
    toggleRand = () => {
        var isOpen = this.isOpen,
            openSpace = this.openSpace,
            drawGridOneOpened = this.drawGridOneOpened;
        if (this.randomInterval) {
            this.randomInterval = false;
            return;
        } else {
            this.randomInterval = true;
            var availTiles = [], rand, newSpace;
            for (var i = 0; i < isOpen.length; i++) {
                if (isOpen[i] !== true) {
                    availTiles.push(i);
                }
            }

            function logRemoved(that) {
                // every 1000ms logs difference in availTiles.length from last run	
                var lastCount = availTiles.length;
                var removedLogger = setInterval(() => {
                    var currentLen = availTiles.length;
                    console.log("Removed in last 1000ms: ", lastCount - currentLen)
                    lastCount = currentLen;
                    if (!that.randomInterval ||
                        // stop if all cells are open (only will occur if don't stop on percolation)
                        that.openCount >= isOpen.length
                    ) {
                        clearInterval(removedLogger);
                    }
                }, 1000);
            }
            // logRemoved(this);


            // console.time("repeater");

            var that = this;
            // let start;
            // timestamp passed automatically by requestAnimationFrame; can use - in conjunction with a var like "start" to change behavior based on 1) total elapsed time since began animation, 2) time since last animation; e.g. could cap speed at which a new space is opened at 60/s, to avoid faster animation on higher refresh rate computers
            var repeater = function (timestamp) {
                // ratio of open cells to total cells
                var openRatio = that.openCount / isOpen.length;

                // after percolation occurs, remove more per frame
                // var removeThisFrame =
                //     that.hasPercolated ?
                //         // use Math.min so don't try to remove more than remain
                //         Math.min(
                //             (isOpen.length - that.openCount),
                //             that.openPerFrame * 8
                //         )
                //         :
                //         that.openPerFrame;

                // below certain open ratio, remove fewer cells per frame
                var removeThisFrame =
                    (openRatio < 0.05) ?
                        Math.floor(that.openPerFrame * (0.1 + openRatio))
                        :
                        that.openPerFrame

                var openedThisFrame = [];

                // speed up by removing mutiple per frame
                for (var i = 0; i < removeThisFrame; i++) {
                    rand = Math.floor(Math.random() * availTiles.length);
                    // splice() returns array of removed values
                    newSpace = availTiles.splice(rand, 1)[0];
                    openSpace(newSpace);
                    openedThisFrame.push(newSpace);
                }
                // if removing many per iteration (e.g. 16 or 32), more efficient to use drawGrid
                that.drawGridFaster(openedThisFrame);
                //     rand = Math.floor(Math.random() * availTiles.length);
                //     // splice() returns array of removed values
                //     newSpace = availTiles.splice(rand, 1)[0];
                //     openSpace(newSpace);
                // *** if only removing 1 or 2 per iteration, more efficient to use drawGridOneOpened
                //     drawGridOneOpened(newSpace);
                that.testPercolation();
                if (that.randomInterval === true &&
                    // stop if all cells are open (only will occur if don't stop on percolation)
                    that.openCount < isOpen.length
                ) {
                    window.requestAnimationFrame(repeater);
                } else {
                    if (that.callbackOnPercolate) {
                        that.callbackOnPercolate();
                    }
                }
            }
            window.requestAnimationFrame(repeater);
        }
    }

    drawRect = (i, border = 0.0) => {
        var ctx = this.ctx,
            columns = this.columns,
            columnWidth = this.canvasWidth / columns,
            widthHeight = columnWidth - border;
        // subtracting border from height/width gives a clear border to each cell
        // use slight negative border to ensure slight overlap, so that grid lines don't appear between the cells on resizing page *** no longer needed, b/c you overlay an SVG at the end, hiding the canvas drawing
        ctx.fillRect(
            (i % columns) * columnWidth,
            Math.floor(i / columns) * columnWidth,
            widthHeight,
            widthHeight
        );
    }

    // for first draw, need to fill in closed (green) squares; for subsequent draws, squares can only change to white or blue
    drawGridInit = () => {
        var ctx = this.ctx,
            columns = this.columns,
            rows = this.rows,
            isOpen = this.isOpen,
            isConnected = this.isConnected;
        console.log("isOpen len", isOpen.length)
        for (var i = 0; i < isOpen.length; i++) {
            if (isOpen[i] === true && isConnected(i, columns * rows)) {
                ctx.fillStyle = this.colorPath;
                this.drawRect(i);
            } else if (isOpen[i] === true) {
                ctx.fillStyle = this.colorOpen;
                this.drawRect(i);
            } else {
                // alternative to drawing these squares: just use background's color for "closed" state; by default, canvas is transparent, so it will show through
                // ctx.fillStyle = this.colorClosed;
                // this.drawRect(i);
            }
        }
    }

    // for first draw, need to fill in closed (green) squares; for subsequent draws, squares can only change to white or blue
    // *** this will get slower and slower as a simulation wears on, as more and more cells will be open; ideally, would only redraw  cells that have changed value
    drawGrid = () => {
        var ctx = this.ctx,
            columns = this.columns,
            rows = this.rows,
            isOpen = this.isOpen,
            isConnected = this.isConnected;
        for (var i = 0; i < isOpen.length; i++) {
            if (isOpen[i] === true && isConnected(i, columns * rows)) {
                ctx.fillStyle = this.colorPath;
                this.drawRect(i);
            } else if (isOpen[i] === true) {
                ctx.fillStyle = this.colorOpen;
                this.drawRect(i);
            }
        }
    }

    // ostensibly faster b/c only setting fillStyle twice, versus once for every single draw
    // also, definitely faster b/c now drawing only newly-opened cells instead of redrawing all opened cells every time (still have to check all cells for connectivity)
    drawGridFaster = (openedThisFrame) => {
        var ctx = this.ctx,
            columns = this.columns,
            rows = this.rows,
            isOpen = this.isOpen,
            isConnected = this.isConnected;

        ctx.fillStyle = this.colorOpen;
        openedThisFrame.forEach(cellIndex => {
            this.drawRect(cellIndex);
        });

        ctx.fillStyle = this.colorPath;
        for (let i = 0; i < isOpen.length; i++) {
            if (isOpen[i] === true && isConnected(i, columns * rows)) {
                this.drawRect(i);
            }
        }
    }

    drawGridOneOpened = (opened) => {
        var ctx = this.ctx,
            columns = this.columns,
            rows = this.rows,
            isOpen = this.isOpen,
            isConnected = this.isConnected;
        ctx.fillStyle = this.colorOpen;
        this.drawRect(opened);
        for (var i = 0; i < isOpen.length; i++) {
            if (isOpen[i] === true && isConnected(i, columns * rows)) {
                ctx.fillStyle = this.colorPath;
                this.drawRect(i);
            }
        }
    }

    connectTopBottomVirtual = (which) => {
        var index = this.index,
            columns = this.columns,
            rows = this.rows,
            union = this.union;
        if (which === "top") {
            // connect only top row to a virtual node
            index[columns * rows] = columns * rows;
            for (var i = 0; i < columns; i++) {
                union(i, columns * rows);
            }
        } else {
            // connect each of top and bottom rows to a virtual node
            index[columns * rows] = columns * rows;
            index[columns * rows + 1] = columns * rows + 1;
            for (var i = 0; i < columns; i++) {
                union(i, columns * rows);
                union((columns * rows - 1) - i, columns * rows + 1)
            }
        }
    }

    openSpace = (id) => {
        var isOpen = this.isOpen,
            columns = this.columns,
            union = this.union;
        if (isOpen[id] === true) {
            console.log("already open");
            return;
        } else {
            // your cute use of, e.g. openCount = this.openCount is no good, e.g. below where you want to increment the class variable but actually only increment the var local to this fnc;
            this.openCount++;
            isOpen[id] = true;
            var cellLeft = id - 1,
                cellRight = id + 1,
                cellAbove = id - columns,
                cellBelow = id + columns;
            // link to cell on left
            if (isOpen[cellLeft] === true &&
                // additional condition is to ensure don't link first cell of a row with last cell of prior row
                ((cellLeft) % columns !== columns - 1)) {
                union(id, cellLeft);
            }
            // link to cell on right
            if (isOpen[cellRight] === true &&
                // additional condition is to ensure don't link last cell of a row with first cell of next row
                ((cellRight) % columns !== 0)) {
                union(id, cellRight);
            }
            // link to cell above
            if (isOpen[cellAbove] === true) {
                union(id, cellAbove);
            }
            // link to cell on below
            if (isOpen[cellBelow] === true) {
                union(id, cellBelow);
            }
        }
    }

    testPercolation = () => {
        var isConnected = this.isConnected,
            columns = this.columns,
            rows = this.rows,
            openCount = this.openCount;
        if (this.isLeaky) {
            if (isConnected(columns * rows, columns * rows + 1)) {
                this.hasPercolated = true;
                // if (this.callbackOnPercolate) {
                //     this.callbackOnPercolate();
                // }
                // console.log("Percolated! Open count = " + openCount);
                if (this.stopOnPercolation) {
                    this.randomInterval = false;
                }
            } else {
                //console.log("Not percolated.");
            }
        } else {
            // prevents "leaking" from bottom, but slower because compares against all bottom cells; only works if you don't couple last row with virtual bottom node
            // by "leaking", I mean "backwards flow" of water from the bottom; e.g. once percolation occurs (which simply means the top and bottom virtual nodes have been connected), all cells connected to the bottom virtual node will be part of that connected group and thus will depict this relationship by "filling" with water.  Technically, that's correct.  For the sake of visualizing your percolation model, it can be undesirable, as it makes most of the model "flood", obsuring the route the water took.
            for (var i = columns * rows - columns; i < columns * rows; i++) {
                if (isConnected(columns * rows, i)) {
                    this.hasPercolated = true;
                    // if (this.callbackOnPercolate) {
                    //     this.callbackOnPercolate();
                    // }
                    // console.log("Percolated! Open count = " + openCount);
                    if (this.stopOnPercolation) {
                        this.randomInterval = false;
                    }
                    return true;
                }
            }
        }
    }

    onMouse = (e) => {
        var columns = this.columns,
            mouse = this.mouse;
        var rect = this.$canvas.getBoundingClientRect();
        this.mouse.x = e.clientX - rect.left;
        this.mouse.y = e.clientY - rect.top;
        var cellWidth = this.canvasWidth / columns;
        var whichColumn = Math.floor(mouse.x / cellWidth);
        var whichRow = Math.floor(mouse.y / cellWidth);
        this.openSpace(whichRow * columns + whichColumn);
        this.drawGrid();
        this.testPercolation();
    }

}

// var $canvas = document.getElementById("canvas");
// var $canvasParent = document.getElementById("gameArea");
// var percolator = new Percolate({
//     canvasParent: $canvasParent,
//     canvas: $canvas,
//     columns: 200,
//     rows: 100,
//     isLeaky: false,
//     openPerFrame: 32,
// });
// percolator.toggleRand();