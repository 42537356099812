import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

const SITE_NAME = "grantkidner.com";

export function Privacy() {
  return (
    <Wrapper>
      <Head>Privacy Policy</Head>
      <div>
        <p>
          This document discusses our policies and practices regarding
          collection, access, use, retention, and disclosure of user information
          (the "Information Practices") related to the website "{SITE_NAME}" and
          certain web and mobile applications (collectively, the website and
          applications are the "Services").
        </p>
        <p>
          If you choose to use any of the Services, then you agree to the
          Information Practices required by the Services you use, and you agree
          you have read, understand, and agree to this policy. We will not use
          or share your information with anyone except as described in this
          Privacy Policy.
        </p>
        <SubHead>Covered Applications</SubHead>
        <p>
          Dominant 7, YAFA, No Minute Left Behind, Law in Hand, Clever Chords,
          and OnePress (the "Apps") are commercial mobile and web applications.
          The Apps are offered as is, with no warranty. Apps may be updated,
          modified, or removed from app stores at any time at our discretion.
        </p>
        <SubHead>User Information</SubHead>
        <p>
          No Minute Left Behind, Law in Hand, Clever Chords, and OnePress
          (collectively the "Offline Apps") do not collect any personally
          identifiable information. Any information collected by the Offline
          Apps through user interaction is maintained solely within the device
          on which an App is installed. No information is communicated across
          the internet to us, or to any third party.
        </p>
        <p>
          To use Dominant 7 or YAFA (collectively the "Online Apps"), you must
          create an account on servers and databases managed by us (the
          "Servers"), and must provide a valid email address to create that
          account. Your email address, other account information, and any text
          or other content you input to Online Apps (collectively "Your
          Information") may be retained on the Servers. None of Your Information
          is communicated to any third party for any purpose, except as
          discussed in the section titled "Third Party Service Providers". to
          the Third Party Service Providers in the normal and required process
          of operating the Apps. The Servers may be inaccessible at any time due
          to maintenance, technical difficulties, or any other reason.
          Information retained on servers, including Your Information, could be
          deleted or otherwise rendered inaccessible at any time, though we do
          take measures to ensure against data loss.
        </p>
        <SubHead>Third Party Service Providers</SubHead>
        <p>
          We may employ third-party companies and/or individuals to help us
          provide the Services (the "Third Party Service Providers"). This
          includes the Third Party Service Providers who possess and operate the
          Servers we manage for the benefit of the Services.
        </p>
        <p>
          Servers are provided to us as a professional service by Third Party
          Service Providers, who have access to Your Information in the normal
          and required course of operating the Apps. The Third Party Service
          Providers operate under their own privacy policies and practices that
          are beyond our control. Please let us know if you would like to learn
          more about these providers.
        </p>
        <SubHead>Security</SubHead>
        <p>
          We strive to use commercially acceptable means of protecting Your
          Information, however no method of transmission over the internet, nor
          any method of electronic storage, is absolutely secure and reliable,
          so we cannot guarantee security.
        </p>
        <SubHead>Log Data</SubHead>
        <p>
          When you use the Services, we may collect diagnostic information that
          the Services and/or your browser sends to us, which may include your
          device's Internet Protocol ("IP") address, browser version, webpages
          of our Services that you visit, the time and date of your visit, the
          time spent on those pages, and other statistics.
        </p>
        <SubHead>Cookies</SubHead>
        <p>
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers. Cookies may be sent to your browser
          from websites that you visit, and then stored on your device.
        </p>
        <p>The Services do not use cookies.</p>
        <SubHead>Links to Third Party Sites</SubHead>
        <p>
          The Services may contain links to websites or apps owned and operated
          by third parties ("Third Party Sites"). If you follow such a link, you
          will be directed to a Third Party Site. Third Party Sites are not
          operated by us, and follow different privacy policies and practices.
          We strongly advise you to review third party policies and practices
          before using their apps, websites, or services. We have no control
          over, and assume no responsibility for the content, policies, or
          practices of any Third Party Sites.
        </p>
        <SubHead>Children and Privacy</SubHead>
        <p>
          The Services are not designed or intended for persons under the age of
          13 ("Children"). We do not knowingly collect personal information from
          Children. If you are a parent or guardian and you believe any of your
          Children have provided us with personal information, please contact
          us.
        </p>
        <SubHead>Changes to This Privacy Policy</SubHead>
        <p>
          We may update this Privacy Policy from time to time. Review this page
          periodically for any changes. We will notify you of any changes by
          posting the new Privacy Policy on this page. Changes are effective
          immediately upon posting.
        </p>
        <SubHead>Contact Us</SubHead>
        <p>
          If you have any questions about our Privacy Policy, please contact us
          at "apps [at] grantkidner.com".
        </p>
      </div>
      <HomeLink to="/">Home</HomeLink>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  background-color: white;
  padding: 2em 2em;
`;

const Head = styled.h1`
  margin-bottom: 12px;
`;
const SubHead = styled.h2`
  margin-bottom: 8px;
`;

const HomeLink = styled(Link)`
  font-size: ${18 / 16}rem;
`;
