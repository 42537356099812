// auto-play app preview videos instead of screenshots
// could include "just for fun" section with little projects, utilities like DrummerBot that you don't have on any app store

import React from "react";
import styled from "styled-components/macro";

import Sections from "./Sections";
import Footer from "./Footer";
import { Hero } from "./Hero";

export function Portfolio() {
  return (
    <Wrapper>
      <Hero />
      <Sections />
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
`;