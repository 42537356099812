import React, { useRef } from "react";
import styled from "styled-components/macro";
import { useInView } from "react-intersection-observer";

import { sectionRefs } from "../_utils";

import { About } from "./About";
import Projects from "./Projects";
import { ContactForm } from "./ContactForm";
import { BREAKPOINTS } from "../_constants";

type Section = Readonly<{
  id: string;
  title?: string | JSX.Element;
  body: string | JSX.Element;
}>;

const sections: Section[] = [
  /*  {
    id: "about",
    title: "Grant, Here!",
    body: <About />,
  }, */
  /*   {
    id: "services",
    title: "Services",
    body: <></>,
  }, */
  {
    id: "projects",
    title: "Projects",
    body: <Projects />,
  },
  {
    id: "contact",
    title: "Contact",
    body: <ContactForm />,
  },
];

const buildThresholdArray = () => Array.from(Array(100).keys(), (i) => i / 100);

type BuildSection = {
  section: Section;
  index: number;
};

function BuildSection({ section, index }: BuildSection) {
  var thisRef = useRef<HTMLDivElement>(null);
  sectionRefs[index] = thisRef;

  const observerOptions = {
    // threshold: buildThresholdArray(),
    threshold: 0,
    triggerOnce: true,
  };

  const { ref, inView, entry } = useInView(observerOptions);

  return (
    <SectionWrapper
      key={section.id}
      ref={thisRef}
      // style={{ opacity: entry?.intersectionRatio }}
      inView={inView}
    >
      <SectionBody ref={ref}>
        <SectionTitle>{section.title}</SectionTitle>
        {section.body}
      </SectionBody>
    </SectionWrapper>
  );
}

function Sections() {
  return (
    <>
      {sections.map((section, index) => (
        <BuildSection section={section} index={index} key={section.id} />
      ))}
    </>
  );
}

export const SectionTitle = styled.header`
  font-size: ${28 / 16 + "rem"};
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  margin-bottom: 16px;

  @media (min-width: ${BREAKPOINTS.lg}) {
    font-size: ${32 / 16 + "rem"};
  }
`;

export const SectionBody = styled.div`
  padding: 32px 16px;
  max-width: ${BREAKPOINTS.lg};
  margin: 0 auto;

  @media (min-width: ${BREAKPOINTS.lg}) {
    padding: 48px 16px;
  }
`;

type SectionWrapper = {
  inView: boolean;
};
export const SectionWrapper = styled.section<SectionWrapper>`
  background-color: var(--color-offwhite);

  @media (min-width: ${BREAKPOINTS.md}) {
    background-color: var(--color-white);

    transform: translate3d(0, 50px, 0);
    opacity: 0.2;
    transition-property: transform, opacity;
    transition-duration: 0.5s;
    transition-delay: 0.2s;
    /* transition-timing-function: linear; */

    ${({ inView }) =>
      inView &&
      `
      transform: none;
      opacity: 1;
      `}
  }

  ${SectionTitle} {
    color: var(--color-primary);
  }

  /* &:first-of-type, */
  &:last-of-type {
    background-color: var(--color-primary);
    ${SectionTitle} {
      color: var(--color-primary-contrast);
    }
  }
`;
export default Sections;
