import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Portfolio } from "./Portfolio";
import { Privacy } from "./Privacy";

import ScrollToTop from "./_components/ScrollToTop";

import GlobalStyles from "./GlobalStyles";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/portfolio">
            <Portfolio />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route exact path="/">
            <Redirect to="/portfolio" />
          </Route>
          <Route path="*">
            <Redirect to="/portfolio" />
          </Route>
        </Switch>
      </Router>

      <GlobalStyles />
    </>
  );
}

export default App;
