import { fetchIt } from '.';

export const apiContact = {
    createWebsiteContact,
};

function createWebsiteContact(websiteContact) {
    const {
        userName,
        // userTitle,
        organizationName,
        userEmail,
        // websiteUrl,
        userPhone,
        messageBody,
        recaptchaToken
    } = websiteContact;
    const path = '/create_website_contact';
    const method = 'POST';
    const body = {
        userName,
        // userTitle,
        organizationName,
        userEmail,
        // websiteUrl,
        userPhone,
        messageBody,
        recaptchaToken
    }
    return fetchIt(path, method, body);
}

