import React from "react";
// import { Link } from 'react-router-dom';
import styled from "styled-components/macro";

import { COLORS } from "../_constants";

const SIZES = {
  sm: {
    "--borderRadius": 2 + "px",
    "--fontSize": 16 / 16 + "rem",
    "--padding": "4px 12px",
  },
  md: {
    "--borderRadius": 2 + "px",
    "--fontSize": 18 / 16 + "rem",
    "--padding": "12px 20px",
  },
  lg: {
    "--borderRadius": 4 + "px",
    "--fontSize": 21 / 16 + "rem",
    "--padding": "16px 32px",
  },
};

const PALETTES = {
  primary: {
    "--mainColor": COLORS.primary,
    "--mainColorLight": COLORS.primaryLight,
    "--mainColorContrast": COLORS.white,
  },
  danger: {
    "--mainColor": "hsl(0, 100%, 50%)",
    "--mainColorLight": "hsl(0, 100%, 75%)",
    "--mainColorContrast": COLORS.white,
  },
};

type Props = {
  variant?: "fill" | "outline" | "ghost";
  size?: "sm" | "md" | "lg";
  palette?: "primary" | "danger";
  href?: string;
  className?: string;
  children: React.ReactNode;
};

const Button = ({
  variant = "fill",
  size = "md",
  palette = "primary",
  href,
  className,
  children,
  ...delegated
}: Props) => {
  const styles = { ...SIZES[size], ...PALETTES[palette] };

  let Component;
  if (variant === "fill") {
    Component = FillButton;
  } else if (variant === "outline") {
    Component = OutlineButton;
  } else if (variant === "ghost") {
    Component = GhostButton;
  } else {
    throw new Error(`Unrecognized Button variant: ${variant}`);
  }

  return (
    <Component
      // if "href" provided, return anchor link; (or could return a React Router "Link")
      as={href ? "a" : "button"}
      href={href}
      // forward className to allow composing this component in a new styled-component
      className={className}
      // forward CSS vars for size/color
      style={styles}
      // forward any additional props
      {...delegated}
    >
      {children}
    </Component>
  );
};

type BaseButton = {
  // style: React.CSSProperties | undefined;
  style?: object;
  href?: string;
};

const ButtonBase = styled.button<BaseButton>`
  font-size: var(--fontSize);
  font-family: "Roboto", sans-serif;
  padding: var(--padding);
  border-radius: var(--borderRadius);
  border: 2px solid transparent;
  outline-color: var(--mainColor);

  &:focus {
    outline-offset: 4px;
  }

  &:active {
    opacity: 0.8;
  }
`;

const FillButton = styled(ButtonBase)`
  background-color: var(--mainColor);
  color: var(--mainColorContrast);

  &:hover {
    background-color: var(--mainColorLight);
  }
`;

const OutlineButton = styled(ButtonBase)`
  background-color: var(--mainColorContrast);
  color: var(--mainColor);
  border: 2px solid currentColor;

  &:hover {
    background-color: ${COLORS.offwhite};
  }
`;

const GhostButton = styled(ButtonBase)`
  color: ${COLORS.gray};
  background-color: transparent;
  outline-color: ${COLORS.gray};

  &:hover {
    background: ${COLORS.transparentGray15};
    color: ${COLORS.black};
  }
`;

export default Button;
