import React from "react";
import styled from "styled-components/macro";

import { BREAKPOINTS, COLORS } from "../_constants";
import { About } from "./About";
import { CanvasHero } from "./CanvasHero";
import { SectionWrapper, SectionBody } from "./Sections";

export function Hero() {
  return (
    <>
      <Wrapper>
        <FlexWrapper>
          <CanvasWrapper>
            <CanvasSizeConstraint>
              <CanvasHero />
            </CanvasSizeConstraint>
          </CanvasWrapper>
          <AboutWrapper>
            <About />
          </AboutWrapper>
        </FlexWrapper>
      </Wrapper>
      <AboutWrapperMobile>
        {/* FIX THIS */}
        <SectionWrapper inView={true}>
          <SectionBody>
            <About />
          </SectionBody>
        </SectionWrapper>
      </AboutWrapperMobile>
    </>
  );
}

// Wrapper is separate from FlexWrapper to provide a full-width color while restraining width of content
const Wrapper = styled.div`
  height: 100%;
  background-color: ${COLORS.primary};

  @media (min-width: ${BREAKPOINTS.md}) {
    height: unset;
    padding: 48px 0;
  }
`;

const FlexWrapper = styled.div`
  height: 100%;
  max-width: ${BREAKPOINTS.lg};

  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.md}) {
    flex-direction: row;
    /* beware: the canvas/svg relies on parents to give it dimensions; it needs stretch to give it height here; if set align-items to "center", will collapse to 0px height */
    align-items: stretch;
    margin: 0 auto;
    padding: 32px;
  }
`;

const CanvasWrapper = styled.div`
  flex: 1 1 0;
`;

const CanvasSizeConstraint = styled.div`
  margin: auto;
  max-width: 300px;
  /* for full-screen hero on mobile, reduce height to set slightly higher in viewport, to make up for downward push of address bar */
  height: 85%;
  width: 100%;
  padding: 0 16px;

  @media (min-width: ${BREAKPOINTS.md}) {
    max-width: unset;
    height: 100%;
  }
`;

const AboutWrapper = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS.md}) {
    display: block;
    flex: 1 1 0;
    padding: 0 16px;
  }
`;

const AboutWrapperMobile = styled.div`
  display: block;
  padding: 0 16px;

  @media (min-width: ${BREAKPOINTS.md}) {
    display: none;
  }
`;
