import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import { scrollToTop } from "../_utils";
import {Spacer} from "../_components/Spacer";

function Footer() {
  return (
    <Wrapper>
      <div>
        <Button onClick={() => scrollToTop()}>
          <FontAwesomeIcon icon={faAngleUp} size="2x" />
        </Button>
      </div>
      <Spacer size={16} axis={"vert"} />
      <Social>
        <Button
          as="a"
          href="https://www.linkedin.com/in/grant-kidner/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
        </Button>
      </Social>
      <Spacer size={16} axis={"vert"} />
      <PrivacyLink as={Link} to="/privacy">
        Privacy
      </PrivacyLink>
      <Copyright>&copy; 2020 - {new Date().getFullYear()} Grant Kidner</Copyright>
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  background-color: rgb(51, 51, 51);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 4px 0px;
`;

const Button = styled.button`
  background: none;
  border: none;
  color: #888;
  padding: 12px 16px;
  border-radius: 0.5em;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus {
    background: #444;
    transform: translateY(-2px);
  }
  &:active {
    background: #fff;
    color: var(--color-primary);
  }
`;

const Social = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const PrivacyLink = styled(Button)`
/*   width: 100%;
  max-width: 360px;
  color: #888;
  padding: 16px 0;
  text-align: center;
  font-size: 0.9rem;
  text-decoration: none; */
`;

const Copyright = styled.div`
  width: 100%;
  max-width: 360px;
  color: #888;
  border-top: 2px solid #888;
  padding: 16px 0;
  text-align: center;
  font-size: 0.9rem;
`;

export default Footer;
