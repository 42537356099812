export const COLORS = {
  primary: "hsl(225, 73%, 57%)",
  primaryLight: "hsl(225, 73%, 75%)",
  primaryTranslucent: "hsla(225, 73%, 57%, 0.2)",
  primaryContrast: "hsl(0deg 0% 100%)",

  shadow: "hsla(0, 0%, 15%, 0.6)",

  white: "hsl(0deg 0% 100%)",
  offwhite: "hsl(235deg 85% 97%)",
  gray: "hsl(240deg 10% 50%)",
  transparentGray15: "hsl(240deg 10% 50% / 0.15)",
  transparentGray75: "hsl(240deg 10% 50% / 0.75)",
  black: "hsl(0deg 0% 0%)",
};

export const BREAKPOINTS = {
  xs: "0px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
};
