import React, { useEffect, createRef } from "react";
import styled from "styled-components/macro";

import { Percolate, scrollToRef } from "../_utils";
import { COLORS } from "../_constants";

export { CanvasHero };

function scrollToFirstSection() {
  scrollToRef(0, 1000);
}

type SvgProps = {
  heroWidth: number;
  viewBox: string;
};
var refSvgShadows = createRef<SVGGElement>();

function SVGOverlay({ heroWidth, viewBox }: SvgProps) {
  function SvgText() {
    return (
      <g>
        <g transform="translate(-75,-79) scale(1.03)">
          <path d="m 104.18204,113.84337 c -2.7432,1.9812 -5.892805,2.7432 -9.296405,2.7432 -8.7376,0 -15.1892,-6.2992 -15.1892,-14.732 0,-8.483602 6.4516,-14.732002 15.24,-14.732002 4.1656,0 7.721605,1.2192 10.718805,4.1656 l 2.3368,-2.3876 c -3.1496,-3.4036 -7.8232,-5.1308 -13.208005,-5.1308 -10.8712,0 -18.795999,7.6708 -18.795999,18.084802 0,10.414 7.924799,18.0848 18.745199,18.0848 4.9276,0 9.702805,-1.524 13.055605,-4.5212 v -13.5636 h -3.6068 z" />
        </g>
        <g transform="translate(-35,-60.75) scale(1.03)">
          <path d="m 104.69004,119.63457 h 4.4704 L 92.701235,100.17817 108.09364,84.074568 h -4.318 L 82.896835,105.56297 V 84.074568 h -3.7592 v 35.560002 h 3.7592 v -9.3472 l 7.2644,-7.366 z" />
        </g>
      </g>
    );
  }

  function SvgRects() {
    return (
      <>
        <rect x="50%" y="0" height="100%" width="2" />
        <rect x="1%" y="67" width="98%" height="3" />
        <rect x="1%" y="77" width="80%" height="2" />
        <rect x="1%" y="85" width="60%" height="1" />
      </>
    );
  }

  return (
    <SVGWrapper viewBox={viewBox}>
      <defs>
        <filter id="shadow">
          <feDropShadow
            dx="0.2"
            dy="0.4"
            stdDeviation="0.2"
            floodColor="#222"
            floodOpacity="1.0"
          />
        </filter>
      </defs>
      <mask id="myMask">
        {/* in an svg mask, white fill is opaque and black fill is transparent */}
        <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
        <g fill="#000">
          <SvgText />
          <SvgRects />
        </g>
      </mask>
      <rect
        x="0"
        y="0"
        // can set pixel or percent size
        width="100%"
        height="100%"
        mask="url(#myMask)"
        fillOpacity="1.0"
        fill={COLORS.primary}
      />
      <SVGGroup ref={refSvgShadows}>
        <g
          stroke="#222"
          strokeWidth="0.2"
          fill={COLORS.primaryContrast}
          filter="url(#shadow)"
        >
          <SvgText />
        </g>
        <g
          fill={COLORS.primaryContrast}
          stroke="#222"
          strokeWidth="0.2"
          filter="url(#shadow)"
        >
          <SvgRects />
        </g>
      </SVGGroup>
    </SVGWrapper>
  );
}

function CanvasHero() {
  var relativeContainerRef = createRef<HTMLDivElement>();
  var canvasParentRef = createRef<HTMLDivElement>();
  var canvasRef = createRef<HTMLCanvasElement>();
  var didPercolate: boolean = false;
  function draw(openPerFrame: number = 128) {
    var percolator = new Percolate({
      relativeContainer: relativeContainerRef.current,
      canvasParent: canvasParentRef.current,
      canvas: canvasRef.current,
      columns: heroWidth * 2.0,
      rows: heroHeight * 2.0,
      isLeaky: false,
      // openPerFrame should be between 32 and 128, depending on desired length of effect
      openPerFrame,
      stopOnPercolation: true,
      callbackOnPercolate: () => {
        // only scroll if page is scrolled all the way up; otherwise annoying for users who have already scrolled down
        /* if (window.scrollY === 0) {
          setTimeout(() => scrollToFirstSection(), 2500);
        } */
        if (refSvgShadows.current) {
          refSvgShadows.current.style.opacity = "1.0";
          refSvgShadows.current.style.setProperty("--hover-opacity", "1.0");
        }
        setTimeout(() => {
          if (canvasParentRef.current) {
            canvasParentRef.current.style.cursor = "pointer";
          }
          didPercolate = true;
          if (refSvgShadows.current) {
            refSvgShadows.current.style.setProperty("--hover-opacity", "0.2");
          }
        }, 1500);
      },
    });
    percolator.toggleRand();
  }
  var heroWidth = 80,
    heroHeight = 100,
    viewBox = "0 0 " + heroWidth + " " + heroHeight;
  useEffect(() => draw());
  return (
    <Wrapper
      ref={relativeContainerRef}
      // onClick={scrollToFirstSection}
      onClick={() => {
        if (didPercolate === true) {
          if (canvasParentRef.current) {
            canvasParentRef.current.style.cursor = "auto";
          }
          if (refSvgShadows.current) {
            refSvgShadows.current.style.opacity = "0.0";
            refSvgShadows.current.style.setProperty("--hover-opacity", "0.0");
          }
          didPercolate = false;
          draw(128);
        }
      }}
    >
      <CanvasWrapper ref={canvasParentRef}>
        <Canvas ref={canvasRef} />
      </CanvasWrapper>
      <SVGOverlay heroWidth={heroWidth} viewBox={viewBox} />
    </Wrapper>
  );
}

// Takes size from parent; will shrink to 0x0px otherwise
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const CanvasWrapper = styled.div`
  /* https://www.html5rocks.com/en/tutorials/casestudies/gopherwoord-studios-resizing-html5-games */
  cursor: auto;
  position: absolute;
  left: 50%;
  top: 50%;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.primary};
  /* though canvas and svg are ostensibly the same size; slight calc differences cause canvas to sometimes "peek" from below svg, creating a line along an edge; add padding to prevent */
  padding: 2px;
`;

// https://stackoverflow.com/questions/9566792/scale-svg-to-container-without-mask-crop
const SVGWrapper = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /* allow SVG shadow filter to overflow container */
  /* https://stackoverflow.com/questions/17883655/svg-shadow-cut-off */
  overflow: visible;
`;

const SVGGroup = styled.g`
  --hover-opacity: 0;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;

  ${Wrapper}:hover & {
    /* JS applies inline style of opacity 1.0 after percolation animation finishes; override with !important */
    opacity: var(--hover-opacity) !important;
  }
`;
