import React, { createRef, useEffect } from "react";
import styled from "styled-components/macro";

// inspiration: https://codepen.io/thenutz/pen/VwYeYEE

type Props = {
  paddingHoriz?: number;
  children: React.ReactNode;
};

// *** if this component gets janky, particularly little jerks as you scroll or if you keep "losing grip" as trying to drag: likely due to the scale transformation triggering the mouseleave event... for some reason! Tried a transition delay, but no dice... presumably due to the scaling changing the scroll distance and mucking up the below calculations mid-stream

export function SideScroller({ paddingHoriz = 0, children }: Props) {
  var refSideScroller = createRef<HTMLDivElement>();

  function applyListeners() {
    const slider = refSideScroller.current;
    const scrollSpeedMult = 2;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;
    slider?.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider?.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider?.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider?.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * scrollSpeedMult;
      slider.scrollLeft = scrollLeft - walk;

      // default behavior: will "anchor" to points at mousedown, so after you reach end of scroll at either end, have to move mouse back past that anchor 0 point before movement will commence in the opposite direction
      // improvement: if at max scroll, reset anchor points to current location and current scroll distance
      // console.table({
      //   scrollLeft: slider.scrollLeft,
      //   offsetWidth: slider.offsetWidth,
      //   scrollWidth: slider.scrollWidth,
      // });
      // allow 1 pixel of leniency in each direction, otherwise pixel rounding or slight movements in user's hand could prevent activation when appropriate
      if (slider.scrollLeft <= 1) {
        scrollLeft = slider.scrollLeft;
        startX = x;
      } else if (
        // max scroll distance
        slider.scrollLeft + slider.offsetWidth >=
        slider.scrollWidth - 1
      ) {
        scrollLeft = slider.scrollLeft;
        startX = x;
      }
    });
  }

  useEffect(() => {
    applyListeners();
  });

  var wrapperStyle = { "--padding-horiz": paddingHoriz + "px" };

  return (
    <BlurWrapper>
      <Wrapper ref={refSideScroller} style={wrapperStyle}>
        {children}
      </Wrapper>
      <EdgeFadeLeft style={wrapperStyle} />
      <EdgeFadeRight style={wrapperStyle} />
    </BlurWrapper>
  );
}

const BlurWrapper = styled.div`
  position: relative;
  user-select: none;
`;

type Wrapper = {
  style: object;
};

const Wrapper = styled.div<Wrapper>`
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  /* transition: transform 0.1s;
  transition-delay: 0.1s;
  transform: scale(1); */
  /* will-change: transform; */
  user-select: none;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  padding-left: var(--padding-horiz);
  padding-right: var(--padding-horiz);

  /* https://stackoverflow.com/a/49278385/6281555 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* Webkit */
    width: 0;
    height: 0;
  }

  &.active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
    /* transform: scale(1.04); */
  }

  /*** 
  Not ideal, as presumes children will always be divs
  Alternative: this component receives array of children, and maps them into containers itself... but then this component may become more opinionated... perhaps make it an "image scroller" to clarify
  */
  & > div {
    display: inline-block;
    margin-right: 24px;
    /* pointer-events: none; */
    /* padding-right: 8px; */
  }
`;

type EdgeFade = {
  style: object;
};

const EdgeFade = styled.div<EdgeFade>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: var(--padding-horiz);
  transform: scale(1.04);
`;

const EdgeFadeLeft = styled(EdgeFade)`
  left: 0;
  background: linear-gradient(
    to right,
    hsla(0, 0%, 100%, 0.5),
    hsla(0, 0%, 100%, 0)
  );
`;

const EdgeFadeRight = styled(EdgeFade)`
  right: 0;
  background: linear-gradient(
    to left,
    hsla(0, 0%, 100%, 0.5),
    hsla(0, 0%, 100%, 0)
  );
`;
