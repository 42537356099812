import { createGlobalStyle } from "styled-components/macro";

import { COLORS, BREAKPOINTS } from "./_constants";

const GlobalStyles = createGlobalStyle`

/* 
    CSS Reset
    https://github.com/hankchizljaw/modern-css-reset 
*/

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100%;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
/* @media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
} */

/* 
    GK Global Styles 
*/

*,
*::before,
*::after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* html {
  font-size: calc(0.333vw + 1em);
} */

html, body, #root {
    height: 100%;
}

html, body {
    background-color:var(--color-primary);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    color: var(--color-black);

  /*   margin-top: constant(safe-area-inset-top, 20px);
    margin-top: env(safe-area-inset-top, 20px);
    margin-bottom: constant(safe-area-inset-bottom, 20px);
    margin-bottom: env(safe-area-inset-bottom, 20px);

    padding-left: constant(safe-area-inset-left, 20px);
    padding-left: env(safe-area-inset-left, 20px);
    padding-right: constant(safe-area-inset-right, 20px);
    padding-right: env(safe-area-inset-right, 20px); */

}


#root {
    /* 
        Create a stacking context - without a z-index.
        This ensures all portal content (e.g. modals, tooltips) will appear on top of the app
    */
    isolation: isolate;
    /* background-color:var(--color-offwhite); */

}

p {
    margin-bottom: 1.5em;
    /* font-size: 1.125rem; */
}

button {
    cursor: pointer;
}

.hidden {
    display: none;
}

/* https://developers.google.com/recaptcha/docs/faq */
.grecaptcha-badge {
    visibility: hidden;
}

:root {
  --color-primary: ${COLORS.primary};
  --color-primary-light: ${COLORS.primaryLight};
  --color-primary-translucent: ${COLORS.primaryTranslucent};
  --color-primary-contrast: ${COLORS.primaryContrast};
  --color-shadow: ${COLORS.shadow};
  /* --color-danger: rgb(255,69,58); */
  --color-danger: hsl(3.35, 100%, 75%);


  --color-black: ${COLORS.black};
  --color-white: ${COLORS.white};
  --color-offwhite: ${COLORS.offwhite};
  --color-shadow: hsla(0, 0%, 15%, 0.6);
  --color-shadow-light: hsla(0, 0%, 15%, 0.4);

  --breakpoint-xs: ${BREAKPOINTS.xs};
  --breakpoint-sm: ${BREAKPOINTS.sm};
  --breakpoint-md: ${BREAKPOINTS.md};
  --breakpoint-lg: ${BREAKPOINTS.lg};
  --breakpoint-xl: ${BREAKPOINTS.xl};

}

`;

export default GlobalStyles;
