// avoid TypeScript error "property [X] does not exist on type 'Window'"
// https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
//https://stackoverflow.com/a/12709880/6281555
declare global {
  interface Window {
    grecaptcha: any;
  }
}

let grecaptcha = window.grecaptcha;

//https://developers.google.com/recaptcha/docs/v3
export function executeRecaptcha() {
  return grecaptcha
    .execute("6Ld3W90ZAAAAAHyksi9tTRuTp92xvJM7moNA3VMt", {
      action: "submit_contact",
    })
    .then(function (token: any) {
      // console.log(token);
      return token;
    });
}

// export function executeRecaptcha() {
//   grecaptcha.ready(function () {
//     grecaptcha
//       .execute("6Ld3W90ZAAAAAHyksi9tTRuTp92xvJM7moNA3VMt", {
//         action: "submit_contact",
//       })
//       .then(function (token: any) {
//         console.log(token);
//         return token;
//       });
//   });
// }
