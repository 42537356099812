import React from "react";

export { sectionRefs, scrollToRef, scrollToTop };

function easeInOut(
  currentTime: number,
  start: number,
  change: number,
  duration: number
): number {
  currentTime /= duration / 2;
  if (currentTime < 1) {
    return (change / 2) * currentTime * currentTime + start;
  }
  currentTime -= 1;
  return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
}

//https://gist.github.com/james2doyle/5694700
function scrollToElAnim(
  element: HTMLElement,
  to: number,
  duration: number = 500
) {
  //despite being identified as compatible with Android 6.0 (API 23), both emulator and device fail to recognize .scroll() and .scrollTo() as functions
  /*element.scroll({
		top: to, 
		left: 0, 
		behavior: 'smooth' 
    });*/
  //
  var start = element.scrollTop,
    change = to - start,
    elapsedTime = 0,
    increment = 20;
  var animateScroll = function () {
    elapsedTime += increment;
    var position = easeInOut(elapsedTime, start, change, duration);
    element.scrollTop = position;
    if (elapsedTime < duration) {
      window.requestAnimationFrame(animateScroll);
    }
  };
  // can call without "window" without error...
  window.requestAnimationFrame(animateScroll);
}

var sectionRefs: React.RefObject<HTMLDivElement>[] = [];

function scrollToRef(i: number, duration: number = 1000) {
  // Note: to perform type guard here to protect against null, you cannot use: if(sectionRefs[i].current); I believe this is because this uses a reference to a variable outside the scope of this function, which means it could be changed at any time.  By binding sectionRefs[i] to a local var, you give TS confidence that the var checked in if() remains the same below the if()
  var thisRef = sectionRefs[i];
  // ensure .current !== null; React will assign the current property with the DOM element when the component mounts, and assign it back to null when it unmounts.
  if (thisRef.current) {
    var scrollHere = thisRef.current.offsetTop;
    // document.documentElement is an alternative to "window" that is IE compatible
    scrollToElAnim(document.documentElement, scrollHere, duration);
  }
}

function scrollToTop(duration: number = 1000) {
  var root = document.getElementById("root");
  if (root) {
    var scrollHere = root.offsetTop;
    // document.documentElement is an alternative to "window" that is IE compatible
    scrollToElAnim(document.documentElement, scrollHere, duration);
  }

  /*  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  }); */
}
