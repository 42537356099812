import * as React from "react";
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik";
import styled from "styled-components/macro";

import { apiContact } from "../_api";
import { executeRecaptcha } from "../_utils/recaptcha";

import { SpinnerOverlay } from "../_components/SpinnerOverlay";
import {Spacer} from "../_components/Spacer";

import { BREAKPOINTS } from "../_constants";

interface Values {
  userName: string;
  organizationName: string;
  userEmail: string;
  userPhone: string;
  messageBody: string;
}

const messageBodyMaxLen: number = 10000;

export const ContactForm = () => {
  return (
    <Wrapper>
      {/* <Tagline>Hey, Grant!</Tagline> */}
      <Formik
        initialValues={{
          userName: "",
          userEmail: "",
          userPhone: "",
          organizationName: "",
          messageBody: "",
        }}
        validate={(values) => {
          const { userName, userEmail, messageBody } = values;
          const errors: any = {};
          const requiredMessage: string = "<Required>";
          if (!userName || userName.trim() === "") {
            errors.userName = requiredMessage;
          }
          if (!userEmail) {
            errors.userEmail = requiredMessage;
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(userEmail)
          ) {
            errors.userEmail = "<Invalid email address>";
          }
          if (!messageBody || messageBody.trim() === "") {
            errors.messageBody = requiredMessage;
          } else if (messageBody.length > messageBodyMaxLen) {
            errors.messageBody = `<Message too long; please remove ${
              messageBody.length - messageBodyMaxLen
            } characters>`;
          }
          return errors;
        }}
        onSubmit={async (
          values: Values,
          { setSubmitting, setStatus }: FormikHelpers<Values>
        ) => {
          try {
            const recaptchaToken = await executeRecaptcha();
            await apiContact.createWebsiteContact({
              ...values,
              recaptchaToken,
            });
            setStatus({ success: true });
          } catch (err) {
            setStatus({ success: false, message: err.message || err });
          } finally {
            // Formik auto calls setSubmitting(false) for an async onSubmit fnc after its promises have resolved
            // setTimeout(() => setSubmitting(false), 1000);
          }
        }}
      >
        {({ errors, status, isSubmitting }) => (
          <>
            <Form
              // suppress native browser validation
              noValidate
            >
              <InputsWrapper>
                <Label>
                  <span>
                    Name *
                    <ErrorMessage name="userName" component={InputWarning} />
                  </span>
                  <Field name="userName" />
                </Label>
                <Label>
                  <span>
                    Email *
                    <ErrorMessage name="userEmail" component={InputWarning} />
                  </span>
                  <Field name="userEmail" type="email" />
                </Label>
                <Label>
                  <span>Phone</span>
                  <Field name="userPhone" type="tel" />
                </Label>
                <Label>
                  <span>Organization</span>
                  <Field name="organizationName" />
                </Label>
                <Label>
                  <span>
                    Message *
                    <ErrorMessage name="messageBody" component={InputWarning} />
                  </span>
                  <Field name="messageBody" as="textarea" />
                </Label>
              </InputsWrapper>
              <Spacer size={8} />
              {Object.keys(errors).length > 0 && (
                <InputWarning>
                  * Please complete all required fields.
                </InputWarning>
              )}
              {status?.success === false && (
                <InputWarning>{`<${status?.message}>`}</InputWarning>
              )}
              <SubmitButton type="submit">Submit</SubmitButton>
              <RecaptchaNotice>
                This site is protected by reCAPTCHA and the Google{" "}
                <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
                and{" "}
                <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
                apply.
              </RecaptchaNotice>
            </Form>
            <SpinnerOverlay
              isSubmitting={isSubmitting}
              success={status?.success}
            />
          </>
        )}
      </Formik>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding: 0 16px;
  color: var(--color-primary-contrast);
  /* if input's font below 1rem, Safari will zoom-in on focus  */
  font-size: ${16 / 16 + "rem"};
  font-weight: 500;

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: ${16 / 16 + "rem"};
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  /* gap: 24px; */

  /* 
  Gap (i.e. grid-gap) for flexbox is not supporting in Safari; below margins provide a workaround 
  https://caniuse.com/flexbox-gap
  https://www.falldowngoboone.com/blog/the-curious-case-of-flexbox-gap-and-safari/
  */
  margin: -8px;
  & > * {
    margin: 8px;
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    margin: -16px;
    & > * {
      margin: 16px;
    }
  }
`;

const Label = styled.label`
  flex: 1 1 calc(var(--breakpoint-md) / 2);
  display: flex;
  flex-flow: column nowrap;
  /* border-left: 4px var(--color-primary-light) solid;
  border-bottom: 4px var(--color-primary-light) solid; */
  /* padding-left: 4px; */
  /* padding-bottom: 4px; */

  input,
  textarea {
    padding: 8px;
    border: none;
    border-radius: 0;
    color: inherit;
    background: inherit;
    border-left: 3px var(--color-primary-light) solid;
    border-bottom: 4px var(--color-primary-light) solid;
    margin-left: -4px;
    margin-top: 2px;
  }
`;

const InputWarning = styled.span`
  color: var(--color-danger);
  /* background-color: var(--color-primary-contrast); */
  margin-left: 8px;
  font-style: italic;
`;

const SubmitButton = styled.button`
  display: block;
  width: 100%;
  max-width: calc(${BREAKPOINTS.sm} / 2);
  margin: 32px auto;
  padding: 8px;

  border: none;
  border-radius: 8px;

  background-color: var(--color-primary-contrast);
  color: var(--color-primary);
  font-weight: 700;
  box-shadow: 0 1px 2px var(--color-shadow);
  transition: all 200ms;

  &:active {
    opacity: 0.8;
    box-shadow: 0 1px 0px var(--color-shadow);
  }
`;

const Tagline = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS.sm}) {
    display: block;
    position: absolute;
    top: -48px;
    right: 0px;
    transform: rotate(-10deg);
    font-style: italic;

    &:before {
      content: "“";
    }

    &:after {
      content: "”";
    }
  }
`;

const RecaptchaNotice = styled.div`
  font-size: 0.8rem;
  font-weight: 400;

  a {
    color: var(--color-primary-contrast);
    text-decoration: underline;
  }
`;
