const API_URL = {
    "development": process.env.REACT_APP_API_URL_DEV,
    "production": process.env.REACT_APP_API_URL_PROD,
}[process.env.NODE_ENV];

const TIMEOUT = 8000;
const FETCH_ATTEMPTS = 5;

export function buildHeaders() {
    var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
    };
    return headers;
}

export async function fetchIt(path, method, body, remainingFetchAttempts = FETCH_ATTEMPTS) {
    process.env.NODE_ENV === "development" &&
        console.log("FETCH", path, method, body);

    const url = `${API_URL}${path}`;
    const options = {
        method: method,
        mode: "cors",
        headers: buildHeaders(),
        body: JSON.stringify(body),
    };
    try {
        let response = await Promise.race([
            fetch(url, options),
            new Promise(
                (resolve, reject) => setTimeout(
                    () => reject(new Error('Timeout')),
                    TIMEOUT
                )
            ),
        ]);
        let responseOK = response && response.ok;
        if (responseOK) {
            let data = await response.json();
            process.env.NODE_ENV === "development" &&
                console.log("JSON data:", data);
            return data;
        } else {
            if ([401, 403].indexOf(response.status) !== -1) {
                // take special action if 401 Unauthorized or 403 Forbidden response returned from api
            }
            console.error("Response not ok; rejecting promise; code: ", response.status);
            try {
                // set error to JSON response, if present, to capture helpful error msgs from server, e.g. "wrong email or password"
                let data = await response.json();
                // const error = data || { success: false, message: `${response.statusText}. Please try again.` };
                console.error(data.message);
                return Promise.reject(data);
            } catch (err) {
                // if no JSON body in response
                console.error(response.statusText);
                return Promise.reject({ success: false, message: `Error: ${response.statusText}. Please try again.` });
            }
        }
    } catch (err) {
        if (err.name === 'TypeError' ||
            err.message === 'Timeout' ||
            err.message === 'Network request failed'
        ) {
            if (remainingFetchAttempts-- > 0) {
                // retry
                return new Promise(resolve => {
                    setTimeout(
                        () => resolve(fetchIt(path, method, body, remainingFetchAttempts)),
                        (1000 * (FETCH_ATTEMPTS / remainingFetchAttempts))
                    );
                });
            } else {
                throw new Error('Please check your connection and try again.');
            }
        } else {
            throw err;
        }
    }
}