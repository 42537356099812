import React from "react";
import styled from "styled-components/macro";
import { BREAKPOINTS } from "../../_constants";

type Props = {
  tech: Array<string>;
};

function Tech({ tech }: Props) {
  return (
    <TechWrapper>
      {tech.map((val, i) => {
        return <TechBubble key={val}>{val}</TechBubble>;
      })}
    </TechWrapper>
  );
}

const TechWrapper = styled.div`
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); */
  /* This is better for small screens, once min() is better supported */
  /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
  /* gap: 8px; */
  /* legacy version of "gap" */
  /* grid-gap: 8px; */

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  /* 
  Gap (i.e. grid-gap) for flexbox is not supporting in Safari; below margins provide a workaround 
  https://caniuse.com/flexbox-gap
  https://www.falldowngoboone.com/blog/the-curious-case-of-flexbox-gap-and-safari/
  */
  margin: -4px;
  & > * {
    margin: 4px;
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    margin: -8px;
    & > * {
      margin: 8px;
    }
  }
`;
const TechBubble = styled.div`
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 2px;
  padding: 4px 8px;
  text-align: center;
  font-size: ${14 / 16 + "rem"};
  font-weight: 500;

  @media (min-width: ${BREAKPOINTS.lg}) {
    font-size: ${18 / 16 + "rem"};
  }
`;

export default Tech;
