import React from "react";
import styled from "styled-components/macro";
import { useInView } from "react-intersection-observer";

import { BREAKPOINTS, COLORS } from "../../_constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faExpand } from "@fortawesome/free-solid-svg-icons";

import { projectList, projectData, Project } from "./projectData";
import { SideScroller } from "../../_components/SideScroller";
import Tech from "./Tech";
import {Spacer} from "../../_components/Spacer";
import { Modal } from "../../_components/Modal";

const shadowStyles = {
  first: {
    "--clip-path": "inset(5px 0px 0px 5px)",
  },
  even: {
    "--clip-path": "inset(0px 0px 0px 5px)",
  },
  odd: {
    "--clip-path": "inset(0px 5px 0px 0px)",
  },
  lastEven: {
    "--clip-path": "inset(0px 0px 5px 5px)",
  },
  lastOdd: {
    "--clip-path": "inset(0px 5px 5px 0px)",
  },
};

type BuildProject = {
  val: Project;
  i: number;
};

function BuildProject({ val, i }: BuildProject) {
  const [selectedImage, setSelectedImage] = React.useState<string>("");
  const [doShowDialog, setDoShowDialog] = React.useState<boolean>(false);

  function open() {
    setDoShowDialog(true);
  }

  function close() {
    setDoShowDialog(false);
  }

  function showImageModal(image: string) {
    setSelectedImage(image);
    open();
  }

  const observerOptions = {
    // threshold: buildThresholdArray(),
    threshold: 0.4,
    triggerOnce: true,
  };

  const { ref, inView, entry } = useInView(observerOptions);

  var shadowStyle;
  if (i === 0) {
    shadowStyle = shadowStyles["first"];
  } else if (i === projectList.length - 1) {
    if (i % 2 !== 0) {
      shadowStyle = shadowStyles["lastOdd"];
    } else {
      shadowStyle = shadowStyles["lastEven"];
    }
  } else if (i % 2 !== 0) {
    shadowStyle = shadowStyles["odd"];
  } else {
    shadowStyle = shadowStyles["even"];
  }

  var shiftStyle = {
    "--shift-amount": i % 2 === 0 ? "-24px" : "24px",
  };

  return (
    <>
      <BorderWrapper>
        {i % 2 !== 0 && <SideBorder />}
        <ShadowWrapper style={shadowStyle}>
          <ProjectWrapper ref={ref} inView={inView} style={shiftStyle}>
            <Head>
              <SVGHeadDecor width="100%" height="100%" viewBox="0 0 8 120">
                <line
                  x1="50%"
                  x2="50%"
                  y1="4%"
                  y2="96%"
                  stroke={COLORS.primary}
                  strokeWidth="8%"
                  strokeLinecap="butt"
                  strokeDasharray="2, 2"
                />
              </SVGHeadDecor>
              <Title>{val.title}</Title>
              <Subtitle>{val.subtitle}</Subtitle>
            </Head>
            {/* <Spacer size={8} axis={"vert"} /> */}
            <PhotoWrapper>
              <SideScroller paddingHoriz={16}>
                {val.images.map((image, i) => {
                  return (
                    <ImageWrapper key={i}>
                      {/* <a href={image} target="_blank" rel="noopener noreferrer"> */}
                      <AppImage src={image} alt={val.title} />
                      {/* </a> */}
                      <ExpandButton onClick={() => showImageModal(image)}>
                        <FontAwesomeIcon icon={faExpand} />
                      </ExpandButton>
                    </ImageWrapper>
                  );
                })}
              </SideScroller>
            </PhotoWrapper>
            <Spacer size={16} axis={"vert"} />
            <LinksWrapper>
              {val.links.length > 0 ? (
                val.links.map((link, i) => {
                  return (
                    <LinksChild key={link.url}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} /> {link.text}
                      </a>
                    </LinksChild>
                  );
                })
              ) : (
                // empty Link child to fill space if no links present
                <LinksChild />
              )}
            </LinksWrapper>
            <AppDescrip>{val.description}</AppDescrip>
            <Tech tech={val.tech} />
          </ProjectWrapper>
        </ShadowWrapper>
        {i % 2 === 0 && <SideBorder />}
      </BorderWrapper>
      {i !== projectList.length - 1 && <BottomBorder />}

      <Modal
        doShowDialog={doShowDialog}
        closeDialog={close}
        doUseLeastDestructiveRef={true}
      >
        <AppImageModal src={selectedImage} alt={val.title} />
      </Modal>
    </>
  );
}

function Projects() {
  return (
    <Wrapper>
      {projectList.map((projectId, i) => {
        let thisProject = projectData[projectId];
        return <BuildProject val={thisProject} i={i} key={projectId} />;
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  @media (min-width: ${BREAKPOINTS.md}) {
    overflow: hidden;
  }
`;

type ShadowWrapper = {
  style?: object;
};

const ShadowWrapper = styled.article<ShadowWrapper>`
  width: 100%;
  padding: 8px 0;

  @media (min-width: ${BREAKPOINTS.md}) {
    padding: 32px 40px;
    box-shadow: inset 0px 0 3px rgba(0, 0, 0, 0.75);
    clip-path: var(--clip-path);
  }
`;

type ProjectWrapper = {
  style?: object;
  inView: boolean;
};

const ProjectWrapper = styled.article<ProjectWrapper>`
  width: 100%;
  /* prevent SideScroller photos from escaping when "zoomed" */
  overflow: hidden;

  background-color: var(--color-white);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 16px;

  & a {
    outline-color: var(--mainColor);
    text-decoration: underline solid var(--color-primary);
  }

  & a:link,
  & a:visited {
    color: var(--color-primary);
  }

  & a:focus,
  & a:hover {
    outline-offset: 4px;
    background: var(--color-primary-translucent);
  }

  & a:active {
    background: var(--color-primary);
    color: #fff;
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    transform: translate3d(var(--shift-amount), 0, 0);
    transition-property: transform;
    transition-duration: 0.5s;
    transition-delay: 0.2s;

    box-shadow: unset;

    /* transition-timing-function: linear; */

    ${({ inView }) => inView && `transform: translate3d(0, 0, 0);`}
  }
`;

const Head = styled.div`
  /* border-left: 4px dotted var(--color-primary); */
  padding-top: 2px;
  padding-bottom: 4px;
  padding-left: 6px;
  /* margin-left should negate border-left + padding-left */
  margin-left: -6px;
  margin-bottom: 16px;
  position: relative;
`;

const SVGHeadDecor = styled.svg`
  position: absolute;
  top: 0;
  left: -6px;
  bottom: 0;
  width: 8px;
  @media (min-width: ${BREAKPOINTS.md}) {
  }
`;

const Title = styled.h1`
  font-size: ${18 / 16 + "rem"};
  font-weight: bold;

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: ${24 / 16 + "rem"};
  }
`;

const Subtitle = styled.span`
  font-size: ${14 / 16 + "rem"};
  color: hsl(0, 0%, 20%);
  font-style: italic;

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: ${18 / 16 + "rem"};
  }
`;

const AppDescrip = styled.div`
  padding: 16px 0;
  font-size: ${14 / 16 + "rem"};
  color: hsl(0, 0%, 20%);

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: ${18 / 16 + "rem"};
  }
`;

const AppImage = styled.img`
  max-height: 250px;
  pointer-events: none;
  border: 1px solid #ccc;

  @media (min-width: ${BREAKPOINTS.md}) {
    max-height: 350px;
  }
`;

const AppImageModal = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const PhotoWrapper = styled.div`
  margin-left: -16px;
  margin-right: -16px;
  /* padding-left: 16px; */
`;

const LinksWrapper = styled.div`
  display: flex;
  /* gap: 16px; */

  /* 
  Gap (i.e. grid-gap) for flexbox is not supporting in Safari; below margins provide a workaround 
  https://caniuse.com/flexbox-gap
  https://www.falldowngoboone.com/blog/the-curious-case-of-flexbox-gap-and-safari/
  */
  margin: -8px;
  & > * {
    margin: 8px;
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    margin: -16px;
    & > * {
      margin: 16px;
    }
  }
`;

const LinksChild = styled.div`
  font-size: ${14 / 16 + "rem"};
  & > a {
    padding: 8px;
  }

  @media (min-width: ${BREAKPOINTS.md}) {
    font-size: ${18 / 16 + "rem"};
  }
`;

const BorderWrapper = styled.div`
  display: flex;
`;

const Border = styled.div`
  background-color: var(--color-primary);
  opacity: 0.5;
  transition: opacity 0.25s;

  @media (min-width: ${BREAKPOINTS.md}) {
    ${Wrapper}:hover & {
      opacity: 1;
    }
  }
`;

const SideBorder = styled(Border)`
  width: 16px;
  display: none;
  @media (min-width: ${BREAKPOINTS.md}) {
    display: block;
  }
`;
const BottomBorder = styled(Border)`
  height: 16px;
  width: 100%;
  display: none;
  @media (min-width: ${BREAKPOINTS.md}) {
    display: block;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const ExpandButton = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: var(--color-shadow-light);
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${24 / 16}rem;
  color: #fff;

  &:active {
    opacity: 0.8;
  }
`;

export default Projects;
