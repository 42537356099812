import React from "react";
import styled from "styled-components/macro";

import { scrollToRef } from "../_utils";
import { SectionTitle } from "./Sections";

export function About() {
  return (
    <Wrapper>
      <SectionTitle>Grant, here!</SectionTitle>
      <Main>
        <p>
          I design, build, and distribute mobile apps, web apps, and websites,
          with a focus on Node-based GraphQL and REST API servers, and React and
          React Native front-ends.
        </p>
        <p>
          In years gone by, I was a partner-level lawyer for a Fortune 300 company,
          handling corporate/securities matters, contracts, and intellectual
          property.
        </p>
        <p>
          Have a look at some of{" "}
          <AnchorButton onClick={() => scrollToRef(0, 1000)}>
            my projects.
          </AnchorButton>
        </p>
        <p>
          If you'd like to talk, please{" "}
          <AnchorButton onClick={() => scrollToRef(1, 1000)}>
            drop me a line.
          </AnchorButton>
        </p>
      </Main>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: var(--color-primary);
  color: white;
`;

const Main = styled.main`
  font-size: 1.1rem;
`;

const AnchorButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  text-decoration: underline solid #fff;
  margin: 0;
  padding: 0;

  &:focus,
  &:hover {
    color: var(--color-primary);
    background-color: var(--color-primary-contrast);
  }

  &:active {
    background: #fff;
  }
`;
