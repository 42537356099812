import React from "react";
import styled from "styled-components/macro";
import {
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogLabel,
} from "@reach/alert-dialog";
import "@reach/dialog/styles.css";
import {
  useTransition,
  animated,
  config,
  AnimationResult,
  Controller,
  SpringValue,
} from "@react-spring/web";

import Button from "./Button";
import {Spacer} from "./Spacer";
import VisuallyHidden from "./VisuallyHidden";

type Props = {
  doShowDialog: boolean;
  closeDialog: () => void;
  // *** ReachUI Dialog's behavior: give focus to first focusable element; use initialFocusRef or doUseLeastDestructiveRef to override
  doUseLeastDestructiveRef?: boolean;
  children?: React.ReactNode;
};

export function Modal({
  doShowDialog,
  closeDialog,
  doUseLeastDestructiveRef = true,
  children,
}: Props) {
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  function handleCancelDialog() {
    closeDialog();
  }

  const transitions = useTransition(doShowDialog, {
    from: { opacity: 0, transform: "translate3d(0,-16px,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0px,0)" },
    leave: { opacity: 0, transform: "translate3d(0,16px,0)" },
    config: {
      ...config.stiff,
      // ...config.molasses,
      // will usually want to use clamp for transition animations, otherwise e.g. here user will believe they are able to touch main page buttons after the modal disappears, but the modal will exist longer than expected, though fully transparent
      clamp: true,
    },
  });

  return (
    <>
      {transitions(
        (style, transitionShowDialog) =>
          transitionShowDialog && (
            <OverlayAnimated
              leastDestructiveRef={cancelRef}
              onClick={closeDialog}
              style={{ opacity: style.opacity }}
              onDismiss={handleCancelDialog}
            >
              <ContentAnimated
                style={{ transform: style.transform }}
                onClick={closeDialog}
              >
                <VisuallyHidden>
                  <StyledAlertDialogLabel></StyledAlertDialogLabel>
                </VisuallyHidden>
                {children}
                {/*  <>
                  <ControlWrapper>
                    <VisuallyHidden>
                      <button
                        ref={doUseLeastDestructiveRef ? cancelRef : null}
                        onClick={handleCancelDialog}
                        // expand="inline"
                      >
                        Cancel
                      </button>
                    </VisuallyHidden>

                    <button
                      onClick={handleCancelDialog}
                      // fill="ghost"
                      // size="sm"
                      // expand="inline"
                    >
                      {"Close"}
                    </button>
                  </ControlWrapper>
                </> */}
              </ContentAnimated>
              {!doShowDialog && <ClosingShield />}
            </OverlayAnimated>
          )
      )}
    </>
  );
}

const Overlay = styled(AlertDialogOverlay)`
  background: var(--color-shadow);
`;
const OverlayAnimated = animated(Overlay);

export const MODAL_PADDING = 8;
const Content = styled(AlertDialogContent)`
  position: absolute;
  /* use fixed value for "top" so when the web view is resized (by Capacitor's Keyboard plugin, with config: resize:"native"), the content doesn't abruptly shift position */
  /* https://stackoverflow.com/questions/47803973/using-calc-with-envsafe-area-inset */
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  /* overriding default restrictions on width/margin/padding */
  margin: 0 auto;
  width: 100%;
  padding: 16px;
  background: none;
`;
const ContentAnimated = animated(Content);

const StyledAlertDialogLabel = styled(AlertDialogLabel)`
  padding: 4px 8px;
  color: var(--color-gray);
`;

const ControlWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ComposedButton = styled(Button)`
  min-width: 80px;
`;

// prevent clicks on modal content (namely, buttons) as it is animating out
const ClosingShield = styled.div`
  color: red;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LoadingSpinnerOverlay = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--color-white-translucent);
  /* center LoadingSpinnerConstraint */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingSpinnerConstraint = styled.div`
  /* without constraint, Spinner will occupy full width, with height equal to that width */
  width: 80px;
`;
