import * as React from "react";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

type Props = {
  isSubmitting: boolean;
  success?: boolean;
};

export const SpinnerOverlay = ({ isSubmitting, success }: Props) => {
  return (
    <Wrapper isSubmitting={isSubmitting} success={success}>
      {success === true ? (
        <Success>
          <div>
            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
            <p id="form_submission_success">Message received!</p>
          </div>
        </Success>
      ) : (
        <SpinnerWrapper>
          <Spinner></Spinner>
        </SpinnerWrapper>
      )}
    </Wrapper>
  );
};

type Wrapper = {
  isSubmitting: boolean;
  success?: boolean;
};

const Wrapper = styled.div<Wrapper>`
  --background-color-default: hsla(0, 0%, 100%, 0.5);
  --background-color-success: hsla(153.33, 78.26%, 54.9%, 0.85);

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background-color: ${(p) =>
    p.success
      ? "var(--background-color-success)"
      : "var(--background-color-default)"};
  border-radius: 4px;
  display: ${(p) => (p.isSubmitting || p.success ? "block" : "none")};
`;

const Success = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: var(--color-primary-contrast);
  padding: 8px;

  p {
    font-size: 1.5rem;
  }
`;

const SpinnerWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled.div`
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
